import { getMiniPicDetailPath, getAdImgAndVideo } from '@/api';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import ptitle from '../../../components/pageTitle/index.vue';
import { useAppStore } from '@/store';
const store = useAppStore();
export default defineComponent({
  components: {
    ptitle
  },
  data() {
    return {
      src: [],
      title: '',
      img: '',
      picture: "",
      route: useRoute()
    };
  },
  async created() {
    this.getallMessage();
  },
  methods: {
    back() {
      let cc = Math.round(Math.random() * 10000); //获得一个随机数，用于延展页面的watch检测
      this.$router.go(-1);
      let dataType = {
        query: {
          pathId: 23,
          name: '影像博物馆',
          listType: 3,
          adShow: 1,
          adNum: 24
        }
      };
      store.setRouter(dataType);
      store.setTopBarIndex(4);
      this.$router.push({
        path: '/richText',
        query: {
          cc: cc
        }
      }); //id为2的时候是富文本模型
    },

    async getallMessage() {
      try {
        const res = await getMiniPicDetailPath({
          farmer_id: 13811,
          phase: store.getRouter().query.phase,
          flag: 1,
          species_id: store.getRouter().query.species_id
        });
        this.src = res.data;
        this.title = res.data.home_setting[0].title;
        console.debug(this.src);
      } catch (e) {
        console.error(e);
      }
      try {
        const img = await getAdImgAndVideo(store.getRouter().query.adNum);
        this.img = img.data.ad_list[0].pic;
      } catch (e) {
        console.error(e);
      }
    }
  },
  watch: {
    '$route': 'getallMessage'
  }
});